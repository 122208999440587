<template>
  <section>
    <v-container fluid pa-0>
      <v-img src="https://s3.us-east-1.wasabisys.com/saltandsea/company/assets/admin.png" style="height: 100vh;">
        <v-btn @click="$router.replace({ name: 'home' })" color="primary" style="z-index: 5" class="ma-2">
          <font-awesome-icon icon="fa-solid fa-backward-step" class="mr-2"/>
          Home
        </v-btn>
        <v-overlay :opacity="0.8" z-index="4">
          <v-row align="center" justify="center" dense>
            <v-col lg="12" class="fill-height d-flex flex-column justify-center align-center">
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-overlay>
      </v-img>
    </v-container>
  </section>
</template>

<script>
export default {}
</script>
